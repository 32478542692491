import React, { useEffect, useState, } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FormControl from "@mui/material/FormControl";
import api from "../../axios";
import { useContext } from "react";
import { AuthContext } from "../../AuthContext";
import MainContext from "../../contexts/MainContext";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import { ListAlt, SwitchLeft, SwitchRight, ToggleOn } from "@mui/icons-material";
// import { tokens } from "../../theme";

const generateDummyData = () => {
  const data = [];

  for (let i = 1; i <= 31; i++) {
    const orderedQuantity = Math.floor(Math.random() * (4000 - 500 + 1)) + 500;
    data.push({ day: i, orderedQuantity });
  }


  return data;
};

const ZoomableBarChart = ({type,chart}) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
//   const allData = generateDummyData();
  const itemsPerPage = 16;
  const [page, setPage] = useState(1);
  const [toggleValue, setToggleValue] = useState("Romulus");
  const [chartData, setChartData] = useState([])

  const {token} = useContext(AuthContext)
  const {selectedSubContract} = useContext(MainContext)
    const getData = () => {

    api
      .get(`dashboard_data/${token.company_id ? token.company_id : token.id}`,{
        params: {
            contract_id: selectedSubContract.id
        }
    })
      .then((response) => {
        
        setChartData(response.data.daily_qty_chart);
        console.log('==============sdfdsf===============');
        console.log(response.data);
        console.log('===============rrrrrrrr=================');
      })
      .catch((error) => {
        if (error.response.status === 403){
          window.location.reload()
        }
      });
  };

  useEffect(() => {
    getData()
    // setChartData(chart)
    console.log("====================================");
    console.log(chart);
    console.log("====================================");
  }, [selectedSubContract])
  



  const handlePrevPage = () => {
    setPage(page - 1);
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };

   const handleToggle = () => {
     setToggleValue(toggleValue === "Romulus" ? "Yours" : "Romulus");
   };

    //  useEffect(() => {
    //    // Fetch data from your Django backend here
    //    api
    //      .get("URL_TO_YOUR_DRF_ENDPOINT")
    //      .then((response) => {
    //        setChartData(response.data); // Update chart data state with fetched data
    //      })
    //      .catch((error) => {
    //        console.error("Error fetching data:", error);
    //      });
    //  }, []);

  const startIdx = (page - 1) * itemsPerPage;
  const endIdx = startIdx + itemsPerPage;
  const currentPageData = chartData.slice(startIdx, endIdx);
  console.log(currentPageData);

  return (
    <div
      style={{
        margin: "auto",
        position: "relative",
        // boxShadow:
        //   "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        boxShadow:"0 2px 6px rgba(0, 0, 0, 0.16)",
        borderRadius: "4px",
      }}
    >
      <div
        className="p-2"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <div>
          <button onClick={handlePrevPage} disabled={page === 1}>
            <ChevronLeftIcon
              fontSize="large"
              style={{ color: page === 1 ? "gray" : "black" }}
            />
          </button>
          <button onClick={handleNextPage} disabled={endIdx >= chartData.length}>
            <ChevronRightIcon
              fontSize="large"
              style={{ color: endIdx >= chartData.length ? "gray" : "black" }}
            />
          </button>
        </div>

        <div className="flex justify-center">
          <button
            onClick={handleToggle}
            style={{
              boxShadow:'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
              border:'1px solid chocolate'
            }}
            className={`rounded-full px-6 py-2 text-amber-600 border border-1 border-amber-600${
              toggleValue === "Romulus" ? "bg-white" : "bg-yellow-500"
            }`}
          >
            {toggleValue} {toggleValue === 'Romulus'?<SwitchRight/>:<SwitchLeft/>}
          </button>
        </div>

        <FormControl className="w-28">
          <select
          style={{background:colors.card['bg']}}
          className="block w-full p-1 text-sm border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:border-indigo-300">
            <option value={1}>September</option>
            {/* <option value={2}>February</option> */}
            {/* Add other months here */}
          </select>
        </FormControl>
      </div>
      <ResponsiveContainer width="100%" height={260}>
        <BarChart data={currentPageData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="day" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="total_qty" fill={colors.pure[900]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ZoomableBarChart;
