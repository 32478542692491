import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
// import Loading from "./LoadingComponent"; // Import your Loading component here
import Loading from "react-loading-components";
import { useContext } from "react";
import { AuthContext } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import { TokenOutlined } from "@mui/icons-material";

function SignLoading() {
  const [isLoading, setIsLoading] = useState(true);
  const [showRefreshLink, setShowRefreshLink] = useState(false);
  const {token} = useContext(AuthContext)

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setShowRefreshLink(true);
//     }, 5000);

//     return () => {
//       clearTimeout(timer);
//     };
//   }, []);

  useEffect(() => {
    
    // Refresh the page after 5 seconds
    const refreshTimeout = setTimeout(() => {
    //   setShowRefreshLink(true);
    window.location.reload()
        
    }, 14000);

    // Clear the timeouts when the component unmounts
    return () => {
      clearTimeout(refreshTimeout);
    };

  }, []);

// const navigate = useNavigate()
    useEffect(() => {
        console.log('====================================');
        console.log(token);
        console.log('====================================');
        // if (token !== 'unauthoried' && token !== null){
        //     navigate('/')
        // }
     
    }, [token])


  // Handle the refresh button click
  const handleRefresh = () => {
    window.location.reload();
  };


    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100vw"
        gap="20px"
      >
        <Loading
          type="ball_triangle"
          width={100}
          height={100}
          fill="chocolate"
        />
        <Typography textAlign="center" variant="h3">
          Signing...
        </Typography>
        
          <Typography variant="body1" onClick={handleRefresh}>
            We are setting things up for you...!!
          </Typography>
        
      </Box>
    );
  // Your content after loading
//   return <div>Content after loading...</div>;
}

export default SignLoading;
