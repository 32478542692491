import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../AuthContext';
import api from '../axios';

// Create the context
const MainContext = createContext();

// Create a provider component to wrap the components that need access to the selected slide state
export const MainContextProvider = ({ children }) => {
  const { token } = useContext(AuthContext)

  const [assetData, setAssetData ] = useState([])
  const [subContractData,setSubContractData] = useState([])
  const [selectedSubContract,setSelectedSubContract] = useState({id:'all',trade_name : 'All'})


  const getContracts = () => {
    let companyid;

    if (token ){
      companyid = token.company_id?token.company_id:token.id
      api.get(`sub-contracts/${companyid}`).then((response) => {
        setSubContractData(response.data);
      }).catch((error)=>{
        if (error.response.status === 403){
          window.location.reload()
        }
      });
    }

  }

  const getAssetData = () => {
    let companyid;

    if (token ){
      companyid = token.company_id?token.company_id:token.id
      api.get(`assets/${companyid}`,{
        params: {
          sub_contract:selectedSubContract.id
        },
      }).then((response) => {
        setAssetData(response.data);
      }).catch((error)=>{
        if (error.response.status === 403){
          window.location.reload()
        }
      });
    }
   
  }




  useEffect(() => {
    // if ()
    getContracts()
    getAssetData()
  }, [token])

  useEffect(() => {
    if (token){
      getAssetData()
    }
  }, [selectedSubContract])


  // useEffect(() => {
  //   // Find the default subContract based on the is_default property
  //   const defaultSubContract = subContractData.find((obj) => obj.is_default);

  //   // Set subContract to the default's id if found, or an empty string if not
  //   setSelectedSubContract(defaultSubContract ? defaultSubContract : "");

  // }, [subContractData]);
  

  const [assetFilter, setAssetFilter] = useState('All');

  return (
    <MainContext.Provider value={{assetFilter, setAssetFilter, assetData, setAssetData, getAssetData, subContractData,selectedSubContract,setSelectedSubContract}}>
      {children}
    </MainContext.Provider>
  );
};

export default MainContext;
