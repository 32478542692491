import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import LoadingIndicator from './components/LoadingIndicator';

const PrivateRoute = ({ path, component:Component , adminOnly }) => {
    const { token,isAuth } = useContext(AuthContext);
  
    
   
    // if (!isAuth){

    // }

    if (token === null) {
      // Render a loading indicator or redirect to a loading page
      return <LoadingIndicator />;
    }else if (token === "unauthorized"){
      return <Navigate to="login" replace />;
    }


      // if (!isAuth) {
      //   console.log("qqqqqq");
      //   return <Navigate to="login" replace />;
      // }

    
    

  
    
  
    return <Component /> ;
  };
  
  export default PrivateRoute;