import * as React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import {
  Home,
  LocalGasStation,
  LocalShipping,
  Payments,
  PaymentsOutlined,
  ShoppingBag,
  ShoppingBagOutlined,
  WorkHistory,
  WorkHistoryOutlined,
} from "@mui/icons-material";
import { List, ListItem, ListItemIcon, ListItemText, Popover } from "@mui/material";

export default function BottomNavbar() {
  const [value, setValue] = React.useState(0);
  const [selected, setSelected] = useState(localStorage.getItem("selected"));
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "history-popover" : undefined;

  const handleSelect = (title) => {
    localStorage.setItem("selected", title);
    if (title === "home") {
      navigate("/");
    } else {
      navigate(title);
    }
    setSelected(title);
    handleClose()
  };

  return (
    <Box sx={{ width: "100%", bottom: 0, position: "fixed" }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{
          maxWidth: "100vw", // Adjust the padding as needed
        }}
      >
        <BottomNavigationAction
          sx={{ minWidth: "10px", maxWidth: "80px" }}
          onClick={() => handleSelect("home")}
          label={
            <span
              style={{
                color: selected === "home" ? "chocolate" : "inherit",
              }}
            >
              Home
            </span>
          }

          icon={
            selected === "home"  ? (
              <Home sx={{ fontSize: 28 , color: selected === "home" ? "chocolate" : "inherit", }} />
            ) : (
              <HomeOutlinedIcon sx={{ fontSize: 28 }} />
            )
          }
        />
        <BottomNavigationAction
          sx={{ minWidth: "10px", maxWidth: "80px" }}
          onClick={() => handleSelect("order")}
          label={
            <span
              style={{
                color: selected === "order" ? "chocolate" : "inherit",
              }}
            >
              Order
            </span>
          }
          icon={
            selected === "order"  ? (
              <ShoppingBag sx={{ fontSize: 28 , color: selected === "order" ? "chocolate" : "inherit", }} />
              ) : (
          <ShoppingBagOutlined sx={{ fontSize: 28 }} />
          )
          }

        />
        {/* <BottomNavigationAction
          sx={{ minWidth: "10px", maxWidth: "80px" }}
          onClick={() => handleSelect("staff")}
          label="Employees"
          icon={<ContactsOutlined sx={{ fontSize: 28 }} />}
        /> */}

        {/* <BottomNavigationAction
        sx={{
          minWidth: '10px',
          maxWidth: '50px',
          borderRadius: '50%',
          height: '50px',
          backgroundColor: 'black',
          marginTop: '-26px',
          color: 'white',
        }}
        label="Order"
        icon={<Add sx={{ fontSize: 24, color: 'white' }} />}
      /> */}

        <BottomNavigationAction
          sx={{ minWidth: "10px", maxWidth: "80px" }}
          onClick={handleClick}
          label={
            <span
              style={{
                color: selected === "clienthistory" ? "chocolate" : selected === 'romulushistory'? 'chocolate' : "inherit",
              }}
            >
              History
            </span>
          }
          icon={
            selected === "clienthistory" ? (
              <WorkHistory sx={{ fontSize: 28 , color:  "chocolate" }} />
              )
              :
              selected === "romulushistory" ? (
                <WorkHistory sx={{ fontSize: 28 , color:  "chocolate" }} />
                )
               : (
          <WorkHistoryOutlined sx={{ fontSize: 28 }} />
          )
          }
        />

        <BottomNavigationAction
          sx={{ minWidth: "10px", maxWidth: "80px" }}
          onClick={() => handleSelect("transactions")}
          label={
            <span
              style={{
                color: selected === "transactions" ? "chocolate"  : "inherit",
              }}
            >
              Transactions
            </span>
          }
          icon={
            selected === "transactions"  ? (
              <Payments sx={{ fontSize: 28 , color: 'chocolate' }} />
              ) : (
          <PaymentsOutlined sx={{ fontSize: 28 }} />
          )
          }
        />
      </BottomNavigation>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top", // Set the vertical anchor origin to 'top'
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom", // Set the vertical transform origin to 'bottom'
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            marginTop: "-8px", // Adjust the marginTop to position the popover above the bottom bar
          },
        }}
      >
        <Box>
          <List>
            <ListItem button 
            onClick={() => handleSelect('clienthistory')}
            >
              <ListItemIcon>
                <LocalGasStation />
              </ListItemIcon>
              <ListItemText primary="Your Orders" />
            </ListItem>

            <ListItem button 
            // onClick={() => handleDelete(row.id)}
            onClick={() => handleSelect('romulushistory')}
            >
              <ListItemIcon>
                <LocalShipping />
              </ListItemIcon>
              <ListItemText primary="Romulus Orders" />
            </ListItem>

          </List>
        </Box>
      </Popover>
    </Box>
  );
}
