import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";

import { useEffect, useState } from "react";
import api from "../axios";

import { useLocation, useParams } from "react-router-dom";
import DeliveryDetailsBox from "../components/OrderDetailsPage/DeliveryDetailsBox";
import OrderDetailsBox from "../components/OrderDetailsPage/OrderDetailsBox";

const OrderDetails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { orderId } = useParams();
  const location = useLocation()
  console.log('====================================');
  console.log(location.state);
  console.log('====================================');


  // const { rowData, setRowData, getRows } = useContext(AuthContext);

  const [rowData, setRowData] = useState({});

  const getRows = async () => {
    await api
      .get(`order-details/${orderId}`)
      .then((response) => {
        console.log("11111111444444", response.data);
        setRowData(response.data);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          window.location.reload();
        }
      });
  };

  useEffect(() => {
    getRows();
  }, []);

  return (
    <Box sx={{ marginInline: "20px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <OrderDetailsBox orderData={rowData} /> 
      </Box>
      <Box>
        <DeliveryDetailsBox orderData={rowData}/>
      </Box>
    </Box>
  );
};

export default OrderDetails;
