import React, { useContext, useState, useEffect } from "react";
import MainContext from "../../contexts/MainContext";
import api from "../../axios";
// import BasicCard from "./Card";
import { AuthContext } from "../../AuthContext";
import {
  Box,
  useMediaQuery,
  useTheme,
  FormControl,
  Typography,
  Button,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import { tokens } from "../../theme";
import { toast } from "react-toastify";
import { Info, InfoOutlined, LocalGasStation, Work } from "@mui/icons-material";

function OrderBox({ data }) {
  const { token } = useContext(AuthContext);
  const {assetData} = useContext(MainContext)
  const company_id = token.company_id ? token.company_id : token.id;
  const isMobile = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [quantity, setQuantity] = useState("");
  const [orderType, setOrderType] = useState('romulus')
  const [fromAsset, setFromAsset] = useState();
  const [toAsset, setToAsset] = useState();
  const [pickupStaff, setPickupStaff] = useState()
  const [deliveryPin, setDeliveryPin] = useState('')
  const [subContract, setSubContract] = useState('')
  const [odometer, setOdometer] = useState("");

  const [total, setTotal] = useState(0);

  const [dieselPrice, setDieselPrice] = useState(85);
  const [discountPrice, setDiscountPrice] = useState(1);
  const [error, setError] = useState("");

  const [locationData, setLocationData] = useState([]);
  const [contractsData, setContractsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [staffData, setStaffData] = useState([])
  
  const getDieselPrice = async () => {
    await api.get(`get-diesel-price/${company_id}`).then((response) => {
      console.log("rrrrrrrrrr", response.data);
      setDieselPrice(response.data.diesel_price);
      setDiscountPrice(response.data.discount ? response.data.discount : 0);
      setLocationData(response.data.locations);
      setContractsData(response.data.sub_contracts)
    });
  };

    const getStaff = () => {
      const params = {
        staff_only: true,
      };
      api
        .get(`staff/${company_id}`, { params })
        .then((response) => {
          setStaffData(response.data);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            window.location.reload();
          }
        });
    };

  useEffect(() => {
    getDieselPrice();
    getStaff()
  }, []);

  const [formData, setFormData] = useState({
    // asset: "",
    requested_quantity: "",
    company: "",
    diesel_price: "",
    total_price: "",
    ordered_by: "",
    ordered_user_type: "",
    order_status: "Ordered",
  });

  const handleChange = (event) => {
    console.log(formData);
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };


  const handleQty = (qty) => {
    setError("");
    setQuantity(qty);
    setTotal(parseFloat(dieselPrice) * qty - parseFloat(discountPrice) * qty);
  };


  const handleSubmit = () => {
    if (subContract === ""){
      setError("Select a Sub Contract");
    } else if (orderType === "romulus" && location === '') { 
          setError("Select One Location");  
      // return
    } else if (quantity === "") {
      setError("Enter the Quantity");
      // return
    } else {
      setIsLoading(true);
      let details = {};
      if (orderType === "client"){
        details = {
          from_datum: fromAsset,
          sub_contract:subContract,
          company: token.company_id ? token.company_id : token.id,
          asset: toAsset,
          odometer,
          assigned_staff: pickupStaff,
          order_type: orderType,
          quantity,
          location,
        };
      }else{
        details = {
        requested_quantity: quantity,
        sub_contract:subContract,
        company: token.company_id ? token.company_id : token.id,
        ordered_by: token.id,
        ordered_user_type: token.user_type,
        diesel_price: dieselPrice,
        discount_price: discountPrice,
        requested_total_price: total,
        order_status: "Ordered",
        order_type: orderType,
        location: location,
      };
      }

      console.log(details);
      api
        .post("order", details)
        .then((response) => {
          toast.success("Order Placed Successfully");
          // setIsLoading(false);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

  };


  const handleDelivery = () => {
    const details = {
      pin:deliveryPin,
      quantity
    }

    api.put('add-datum-delivery',details).then((response)=>{
  
    }).catch((response)=>{
      toast.error('Invalid Pin')
    })
  }

  const [location, setLocation] = useState("");

  const handleLocationChange = (event) => {
    setError("");
    setLocation(event.target.value);
  };

    const assetIcons = {
      All: require("../../assets/industry.png"),
      Datum: require("../../assets/datum.png"),
      HeavyVehicle: require("../../assets/truck.png"),
      InfraMachine: require("../../assets/citytech-logo.png"),
      DgSet: require("../../assets/generator.png"),
      Industrial: require("../../assets/factory.png"),
      Others: require("../../assets/oil-pump.png"),
    };

    const handleSubContractClick = (contractId) => {
      setSubContract(contractId);
    };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: 20,
        flexDirection: "row",
        padding: 8,
        justifyContent: "center",
        alignItems: "center",
        // paddingBottom: isMobile ? 50 : 50,
      }}
    >
      {/* {data.map((obj) => {
        console.log('kkkkkkkkkkkkkkk',obj);
        return (            
              <div style={{ flexBasis: "50%", maxWidth: 320 }}>
                <BasicCard
                  staffName={obj.username}
                  staffNumber={obj.number}
                  assetInfo={obj.assets_incharge}
                  assetType={obj.assets_incharge?.typeOfAsset}
                  sx={{ width: "500px"}} />
              </div>   )  
      })} */}

      <Box
        display="flex"
        backgroundColor={colors.card2["bg"]}
        width="100%"
        // paddingX="10%"
        paddingY="2%"
        borderRadius="10px"
        // gap="40px"
        justifyContent="center"
        alignItems="center"
        boxShadow="0 0 8px rgba(0, 0, 0, 0.3)"
        flexDirection={isMobile ? "column" : "column"}
      >
        <Box width={isMobile ? "100%" : "60%"} sx={{paddingInline:'10px'}}>
          <div className="relative mb-1">
          <div className="mr-2 font-semibold mt-2 text-center">Order from:</div>
            <div className="flex mb-2 justify-center align-center">
              {/* Option 1 */}
              

              <div
                className={`px-4 py-2 cursor-pointer ${
                  orderType === "romulus"
                    ? "bg-amber-600 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
                onClick={() => setOrderType("romulus")}
              >
                Romulus
              </div>

              {/* Option 2 */}
              <div
                className={`px-4 py-2 cursor-pointer ${
                  orderType === "client"
                    ? "bg-amber-600 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
                onClick={() => setOrderType("client")}
              >
                Your Datum
              </div>

              {token.is_datum_operator || token.user_type === 'company' && <div
                className={`px-4 py-2 cursor-pointer ${
                  orderType === "delivery"
                    ? "bg-amber-600 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
                onClick={() => setOrderType("delivery")}
              >
                Add Delivery
              </div>}
            </div>

        {orderType !== 'delivery' && <>
            {/* <select
              value={subContract}
              onChange={(e)=>setSubContract(e.target.value)}
              className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-indigo-500 focus:shadow-outline"
            >
              <option value="">Select Sub Contract</option>

              {contractsData.map((obj) => (
                <option value={obj.id}>{obj.trade_name}</option>
              ))}
            </select> */}

<div className="w-full max-w-md mx-auto pb-1">
      <div className="flex space-x-2">
        <div className="flex items-center text-gray-600">
          <IconButton disabled>
            <Work />
          </IconButton>
          <span>Select Sub Contract:</span>
        </div>
        {contractsData.map((contract) => (
          <button
            key={contract.id}
            className={`px-4 py-2 border ${
              subContract === contract.id
                ? 'bg-amber-600 text-white'
                : 'bg-white text-gray-700 hover:bg-indigo-100'
            } rounded-full`}
            onClick={() => handleSubContractClick(contract.id)}
          >
            {contract.trade_name}
          </button>
        ))}
      </div>
    </div>
            
          </>}

            {orderType === "romulus" ? (
              <>
                {" "}
                <select
                style={{ backgroundColor: colors.card["bg"]}}
                  value={location}
                  onChange={handleLocationChange}
                  className="block appearance-none w-full bg-white border border-gray-300 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-indigo-500 focus:shadow-outline"
                >
                  <option value="">Select a location</option>

                  {locationData.map((obj) => (
                    <option value={obj.id}>{obj.location}</option>
                  ))}
                </select>
                {/* <div className="pointer-events-none absolute inset-y-0 top-10 right-0 flex items-center px-2 text-gray-700">
                  <LocationOnOutlined />
                </div> */}
              </>
            ) : orderType === "client" ? (
              <>
                <select
                style={{ backgroundColor: colors.card["bg"]}}
                  value={location}
                  onChange={handleLocationChange}
                  className="block appearance-none w-full bg-white border border-gray-300 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-indigo-500 focus:shadow-outline"
                >
                  <option value="">Select a location</option>

                  {locationData.map((obj) => (
                    <option value={obj.id}>{obj.location}</option>
                  ))}
                </select>
                {/* <div className="pointer-events-none absolute inset-y-0 top-10 right-0 flex items-center px-2 text-gray-700">
                  <LocationOnOutlined />
                </div> */}

                <FormControl fullWidth margin="normal">
                  <InputLabel id="asset-state-label">From Datum</InputLabel>
                  <Select
                    disabled={orderType === "romulus"}
                    name="asset_incharge"
                    value={fromAsset}
                    onChange={(e) => setFromAsset(e.target.value)}
                    variant="filled"
                  >
                    <MenuItem value="">Select From Datum</MenuItem>
                    {assetData
                      .filter((obj) => obj.typeOfAsset === "Datum")
                      .map((obj) => {
                        let assetIcon = assetIcons["Datum"];

                        return (
                          <MenuItem value={obj.id}>
                            <Box
                              width="100%"
                              display="flex"
                              flexDirection="row"
                              gap="5px"
                              justifyContent="space-between"
                            >
                              <Box display="flex" flexDirection="row" gap="6px">
                                <img
                                  src={assetIcon}
                                  style={{ filter: colors.pngcol["filter"] }}
                                  width="35"
                                  height="35"
                                />
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  marginTop="-3px"
                                >
                                  <Typography fontWeight="700">
                                    {obj.assetName}
                                  </Typography>

                                  <Typography fontSize="13px">
                                    {obj.typeOfAsset}
                                  </Typography>
                                </Box>
                              </Box>

                              {/* <Box marginTop="-3px" width="25%" paddingLeft="5px">
                              <Typography>
                                {obj.assetRegistrationNumber !== ""
                                  ? `Reg No : ${obj.assetRegistrationNumber}`
                                  : `Capacity : ${obj.assetCapacity} Ltrs`}
                              </Typography> */}

                              {/* <Typography variant="p" fontSize="14px">
                          Location:dfsdfsd
                        </Typography> */}
                              {/* </Box> */}
                            </Box>
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <InputLabel id="asset-state-label">
                    Select the To asset
                  </InputLabel>
                  <Select
                    disabled={orderType === "romulus"}
                    name="asset_incharge"
                    value={toAsset}
                    onChange={(e) => setToAsset(e.target.value)}
                    variant="filled"
                  >
                    <MenuItem value="">None</MenuItem>
                    {assetData.map((obj) => {
                      let assetIcon = assetIcons["All"];
                      if (obj.typeOfAsset === "Datum") {
                        assetIcon = assetIcons["Datum"];
                      } else if (obj.typeOfAsset === "Heavy vehicle") {
                        assetIcon = assetIcons["HeavyVehicle"];
                      } else if (obj.typeOfAsset === "Infra machine") {
                        assetIcon = assetIcons["InfraMachine"];
                      }
                      return (
                        <MenuItem value={obj.id}>
                          <Box
                            width="100%"
                            display="flex"
                            flexDirection="row"
                            gap="5px"
                            justifyContent="space-between"
                          >
                            <Box display="flex" flexDirection="row" gap="6px">
                              <img
                                src={assetIcon}
                                style={{ filter: colors.pngcol["filter"] }}
                                width="35"
                                height="35"
                              />
                              <Box
                                display="flex"
                                flexDirection="column"
                                marginTop="-3px"
                              >
                                <Typography fontWeight="700">
                                  {obj.assetName}
                                </Typography>

                                <Typography fontSize="13px">
                                  {obj.typeOfAsset}
                                </Typography>
                              </Box>
                            </Box>

                            <Box marginTop="-3px" width="25%" paddingLeft="5px">
                              <Typography>
                                {obj.assetRegistrationNumber !== ""
                                  ? `Reg No : ${obj.assetRegistrationNumber}`
                                  : `Capacity : ${obj.assetCapacity} Ltrs`}
                              </Typography>

                              {/* <Typography variant="p" fontSize="14px">
                          Location:dfsdfsd
                        </Typography> */}
                            </Box>
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                  <label htmlFor='odometer'>Enter the Asset Odometer</label>
                <input
            required
            style={{ backgroundColor: colors.card["bg"] }}
            name="odometer"
            value={odometer}
            placeholder="Odometer Reading"
            onChange={(e) => {
              setOdometer(e.target.value)
            }}
            className="w-full bg-white px-4 py-2 rounded-sm border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />

<label htmlFor='pickupstaff'>Select the staff for Pickup</label>
                <select
                style={{ backgroundColor: colors.card["bg"]}}
                name='pickupstaff'
                  value={pickupStaff}
                  onChange={(e) => setPickupStaff(e.target.value)}
                  className="block appearance-none w-full bg-white border border-gray-300 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-indigo-500 focus:shadow-outline"
                >
                  <option value="">Select a Pickup Staff</option>

                  {staffData.map((obj) => (
                    <option value={obj.id}>{obj.username}</option>
                  ))}
                </select>
                {/* <div className="pointer-events-none absolute inset-y-0 top-10 right-0 flex items-center px-2 text-gray-700">
                  <LocationOnOutlined />
                </div> */}
              </>
            ) : (
              <input
                required
                style={{ backgroundColor: colors.card["bg"] }}
                name="delivery_pin"
                value={deliveryPin}
                placeholder="Delivery Pin"
                onChange={(e) => {
                  setDeliveryPin(e.target.value);
                }}
                className="w-full bg-white px-4 py-2 rounded-sm border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            )}
          </div>

          {/* <FormControl fullWidth className="bg-white"> */}
          <label htmlFor='quantity'>Enter the Quantity</label>
          <input
            required
            style={{ backgroundColor: colors.card["bg"] }}
            name="quantity"
            value={quantity}
            placeholder="Quantity in Liters"
            onChange={(e) => {
              handleQty(e.target.value);
            }}
            className="w-full bg-white px-4 py-2 rounded-sm border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />

          <Box
            style={{
              display: "flex",
              marginTop: "8px",
              gap: 1,
              overflowX: "auto",
              paddingTop: "5px",
              "&::-webkit-scrollbar": {
                width: "1px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "red", // Change scrollbar thumb color (Chrome, Safari)
              },
            }}
          >
            <Button
              variant="contained"
              sx={{
                bgcolor: colors.grey[900],
                color: colors.pure[900],  
                "&:hover": { bgcolor: colors.grey[800] },
              }}
              onClick={() => {
                handleQty(30);
              }}
            >
              30
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: colors.grey[900],
                color: colors.pure[900],
                "&:hover": { bgcolor: colors.grey[800] },
              }}
              onClick={() => {
                handleQty(50);
              }}
            >
              50
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: colors.grey[900],
                color: colors.pure[900],
                "&:hover": { bgcolor: colors.grey[800] },
              }}
              onClick={() => {
                handleQty(100);
              }}
            >
              100
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: colors.grey[900],
                color: colors.pure[900],
                "&:hover": { bgcolor: colors.grey[800] },
              }}
              onClick={() => {
                handleQty(200);
              }}
            >
              200
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: colors.grey[900],
                color: colors.pure[900],
                "&:hover": { bgcolor: colors.grey[800] },
              }}
              onClick={() => {
                handleQty(300);
              }}
            >
              300
            </Button>

            <Button
              variant="contained"
              sx={{
                bgcolor: colors.grey[900],
                color: colors.pure[900],
                "&:hover": { bgcolor: colors.grey[800] },
              }}
              onClick={() => {
                handleQty(500);
              }}
            >
              500
            </Button>

            <Button
              variant="contained"
              sx={{
                bgcolor: colors.grey[900],
                color: colors.pure[900],
                "&:hover": { bgcolor: colors.grey[800] },
              }}
              onClick={() => {
                handleQty("");
              }}
            >
              Custom
            </Button>
          </Box>
          {/* </FormControl> */}
        </Box>

        <Box
          width="100%"
          display="flex"
          gap="5px"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="row"
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="row"
              alignItems="center"
              sx={{
                border: "solid 1px darkgrey",
                p: 1,
                borderRadius: 1,
                mb: 1,
                mt: 2,
                width: !isMobile ? "450px" : "auto",
              }}
            >
              <LocalGasStation sx={{ fontSize: "45px" }} />
              <Grid container alignItems="center" spacing={1}>
                <Grid item sm={6}>
                  <Typography fontSize="15px">Diesel Price:</Typography>
                  <Typography fontSize="15px">Discount Price:</Typography>
                  <Typography fontSize="15px">Sub Total:</Typography>
                  <Typography fontSize="15px">Total Discount:</Typography>
                  <Typography fontSize="15px" fontWeight="700">
                    Total Amount:
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography fontSize="15px" textAlign="right">
                    {`Rs. ${dieselPrice}/Ltr`}
                  </Typography>
                  <Typography fontSize="15px" textAlign="right">
                    {`Rs. ${discountPrice}/Ltr`}
                  </Typography>
                  <Typography fontSize="15px" textAlign="right">
                    {`Rs. ${quantity * dieselPrice}`}
                  </Typography>
                  <Typography fontSize="15px" textAlign="right">
                    {`- Rs. ${quantity * discountPrice}`}
                  </Typography>
                  <Typography
                    fontSize="15px"
                    fontWeight="700"
                    textAlign="right"
                  >
                    <span
                      style={{
                        backgroundColor: "blue",
                        borderRadius: "5px",
                        paddingInline: "4px",
                        color: "white",
                      }}
                    >{`Rs. ${total}`}</span>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {orderType === "client" && (
            <Typography
              sx={{ fontSize: "10px", fontWeight: "600" }}
              color="chocolate"
            >
              <InfoOutlined sx={{ mb: 0.2 }} /> Client Side Orders taken from
              your Datum will not be billed, Amount is just for reference
            </Typography>
          )}

          {/* <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <LocalGasStationIcon sx={{ fontSize: "45px" }} />

            <Typography fontSize="15px" fontWeight="700">
              Diesel Price : {`Rs. ${dieselPrice}/Ltr`}
            </Typography>

            <Typography fontSize="15px" fontWeight="700">
              Discount Price : {`Rs. ${discountPrice}/Ltr`}
            </Typography>

            <Typography fontSize="15px" fontWeight="700">
              Total Price :{" "}
              <span
                style={{
                  backgroundColor: "blue",
                  borderRadius: "5px",
                  paddingInline: "4px",
                  color: "white",
                }}
              >{`Rs. ${total}`}</span>
            </Typography>
          </Box> */}

          {error && <span className="text-red-600 font-bold"><Info color="red"/> {error}...!</span>}

          {!isLoading ? (
            
              <Button onClick={orderType === 'delivery'? handleDelivery: handleSubmit} size="large" variant="contained">
              {orderType === 'delivery'?'Add Delivery':'Order Now'}
              </Button>
             
          ) : (
            <Button disabled size="large" variant="contained">
              Ordering Please Wait
            </Button>
          )}
        </Box>
      </Box>
    </div>
  );
}

export default OrderBox;
