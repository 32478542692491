import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import api from "../../axios";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../AuthContext";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import rmlogo from "../../assets/rmlogo.png";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";
import SignLoading from "../../components/SignLoading";

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {
  // const [isBtLoading, setIsBtLoading] = useState(false)

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    const {checkAuth, checkLoad, setToken } = useContext(AuthContext)


    

  const handleSubmit = async (event) => {
    setToken(null)
    setIsLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get("username");
    const password = data.get("password");
    const details = { username, password };
    localStorage.setItem("selected", "Home");

    try {
      await api
        .post(`${process.env.REACT_APP_ADMIN_BASEURL}login`, details, {
          withCredentials: true,
        })
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            console.log("sssssss", response.data.user);
            // window.location.reload();
            checkAuth()
          }
        });
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data);
        setIsLoading(false)
      } else {
        toast.error("Something Went Wrong..!! Please try again");
        setIsLoading(false)
      }
    }
  };

  const { isAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  //   setIsLoading(true)

  
    // useEffect(() => {
    //   checkAuth()
    // }, []);

    useEffect(()=>{
      if (checkLoad){
        console.log('====================================');
        console.log('checkLoaaaaaaaaaad');
        console.log('====================================');
        setIsLoading(true)
      }
      else{
        console.log("====================================");
        console.log("checkLoaaaaaaaaaad Falsee");
        console.log("====================================");
        setIsLoading(true);
      }
    },[checkLoad])


    // if (checkLoad){
    //   return <LoadingIndicator/>
    // }

  useEffect(() => {
        
    // setIsLoading(true)
    if(!isAuth){
      setIsLoading(true)
    }
    if (isAuth == true) {
      navigate("/");
    }
    setIsLoading(false);
  }, [isAuth]);



  if (isLoading) {

    return (
      <SignLoading/>
      // <Box
      //   display="flex"
      //   flexDirection="column"
      //   justifyContent="center"
      //   alignItems="center"
      //   width='100vw'
      //   gap='20px'
      // >
        
      //   <Loading type='ball_triangle' width={100} height={100} fill='chocolate' />
      //   <Typography textAilgn='center' variant='h3'>Signing...</Typography>
      // </Box>
    );
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <LockOutlinedIcon /> */}
          <img src={rmlogo} width="80" height="70"></img>
          <Typography
            fontWeight="700"
            sx={{ color: "chocolate" }}
            component="h1"
            variant="h5"
          >
            ROMULUS
          </Typography>
          <Typography
            sx={{ color: "chocolate" }}
            fontWeight="700"
            component="h1"
            variant="h5"
          >
            OIL & GAS
          </Typography>
          <Typography
            // textAlign='left'
            // sx={{color:'chocolate'}}
            fontWeight="500"
            fontSize="1.2rem"
            component="h3"
            variant="h5"
          >
            Login
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
            />
            {/* <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            /> */}

            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              // onClick={handleSubmit}
              fullWidth
              variant="contained"
              color="warning"
              sx={{ mt: 3, mb: 2, bgcolor: "chocolate" }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/forgotpass")}
                  variant="body2"
                >
                  Forgot password?
                </div>
              </Grid>
              <Grid item>
                {/* <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link> */}
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
    </ThemeProvider>
  );
}
