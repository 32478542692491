import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { CustomToast } from "../customtoast/CustomToast";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// import DataUsageIcon from '@mui/icons-material/DataUsage';
// import BuildIcon  from '@mui/icons-material/Build ';
import { AuthContext } from "../../AuthContext";
import api from "../../axios";
import MainContext from "../../contexts/MainContext";
import { useState } from "react";
import { Work } from "@mui/icons-material";

const assetType = ({type}) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <AddCircleOutlineIcon />
      <Typography variant="body2">Datum</Typography>
    </Box>
  );
};

export default function AddCompanyModal({ updateData,type }) {
  const [open, setOpen] = React.useState(false);
  const [isDatumOperator, setIsDatumOperator] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [phone, setPhone] = useState('')
  const [subContract, setSubContract] = useState('')

  const {subContractData} = React.useContext(MainContext)
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setFormData({
      company_id: token.id,
      username: "",
      asset_incharge: "",
      number: "",
      password: "",
      is_datum_operator :'',
      role: type
    });
    setOpen(false);
  };

  const isMobile = useMediaQuery("(max-width: 600px)");

  const { token } = React.useContext(AuthContext);

  const [formData, setFormData] = React.useState({
    company_id: token.id,
    username: "",
    asset_incharge: "",
    number: "",
    password: "",
    is_datum_operator:"",
    role: type,
  });



  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90vw" : "50vw",
    maxHeight: "80vh",
    bgcolor: colors.background[900],
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
  };

  const [error, setError] = React.useState(null);

  const handleChange = (event) => {
    
    const { name, value } = event.target;
    console.log('====================================');
    console.log(name, value);
    console.log('====================================');
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
  };

  const [confirmPassword, setConfirmPassword] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault()
    if (confirmPassword === password) {
      // setFormData((prevData) => ({ ...prevData, ['role']: 'staff' }));
      // setFormData((prevData) => ({ ...prevData, ['role']: 'company' }));

      console.log(isDatumOperator);
      // setFormData((prevData) => ({ ...prevData, ["is_datum_operator"]: isDatumOperator }));
      const details = {
        company_id:token.company_id?token.company_id:token.id,
        username,
        number:phone,
        password,
        staff_sub_contract:subContract,
        is_datum_operator:isDatumOperator,
        role:type
      }
      api
        .post("staff", details)
        .then((response) => {
         updateData();
          handleClose();
        })
        .catch((error) => {
          
          const errorMessages = error.response.data;
          const keys = Object.keys(errorMessages);
          const values = Object.values(errorMessages);
          console.log('====================================');
          console.log('dddd',errorMessages);
          console.log('===================================='); 
          toast.error(<CustomToast keys={keys} values={values}/>);
          if (error.response.status === 403){
            window.location.reload()
          }
        });
    } else {
      toast.error("Confirm Password Does not Match");

    }
  };

  const { assetData } = React.useContext(MainContext);

  //   React.useEffect(() => {

  //   }, [])

  return (
    <div>
      <button
        style={{ 
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px'
         }}
        className="text-amber-600 border border-1 border-amber-600 bg-white font-bold py-2 px-4 rounded-lg hover:bg-amber-700 hover:text-white transition duration-900"
        onClick={handleOpen}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 mr-1 inline-block"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 4v16m8-8H4"
          />
        </svg>
        Add {type}
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={style}
          className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg"
        >
          <h3 className="text-3xl text-center mb-6">Add {type} Details</h3>
          <div className="flex justify-end">
            <p className="mr-2 cursor-pointer" onClick={handleClose}>
              Close
            </p>
            <CloseIcon className="cursor-pointer" onClick={handleClose} />
          </div>

          <form>
            <div className="mb-4">
              <label htmlFor="username" className="block mb-2 font-bold">
                Staff Username
              </label>
              <input
                id="username"
                name="username"
                value={username}
                className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            <div className="w-full max-w-md mx-auto pb-1">
      <div className="flex space-x-2">
        <div className="flex items-center text-gray-600">
          <IconButton disabled>
            <Work />
          </IconButton>
          <span>Select Sub Contract:</span>
        </div>
        {subContractData.map((contract) => (
          <div
            key={contract.id}
            className={`px-4 py-2 border ${
              subContract === contract.id
                ? 'bg-indigo-500 text-white'
                : 'bg-white text-gray-700 hover:bg-indigo-100'
            } rounded-full`}
            onClick={(e) => setSubContract(contract.id)}
          >
            {contract.trade_name}
          </div>
        ))}
      </div>
    </div>

            <div className="mb-4">
              <label htmlFor="number" className="block mb-2 font-bold">
                Phone Number
              </label>
              <input
                id="number"
                name="number"
                value={phone}
                autoComplete="off"
                className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <div className="mt-2">
              <p>Datum Operator..?</p>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="datum_operator"
                  value={true}
                  className="form-radio h-5 w-5 text-indigo-600"
                  checked={isDatumOperator}
                  onChange={() => setIsDatumOperator(true)}
                />
                <span className="ml-2">Yes</span>
              </label>
              <label className="inline-flex items-center ml-4">
                <input
                  type="radio"
                  name="datum_operator"
                  value={false}
                  className="form-radio h-5 w-5 text-indigo-600"
                  checked={isDatumOperator === false}
                  onChange={() => setIsDatumOperator(false)}
                />
                <span className="ml-2">No</span>
              </label>
            </div>

            <div className="flex space-x-4 mb-4">
              <div className="w-1/2">
                <label htmlFor="password" className="block mb-2 font-bold">
                  Set a Password
                </label>
                <input
                  autoComplete="off"
                  id="password"
                  name="password"
                  value={password}
                  type="password"
                  className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="w-1/2">
                <label
                  htmlFor="confirm_password"
                  className="block mb-2 font-bold"
                >
                  Confirm Password
                </label>
                <input
                  autoComplete="off"
                  id="confirm_password"
                  name="confirm_password"
                  type="password"
                  value={confirmPassword}
                  className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="flex justify-center">
              <button
                // type="submit"
                onClick={handleSubmit}
                className="px-6 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
