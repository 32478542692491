import React, { createContext, useState,useEffect } from 'react';
import LoadingIndicator from './components/LoadingIndicator';
// import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import api from './axios';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
//   const [isAdmin, setIsAdmin] = useState(localStorage.getItem('isAdmin') || false);
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [checkTrigger,setCheckTrigger] = useState(false)
  const [checkLoad,setCheckLoad] = useState(false)
  const navigate = useNavigate()
  

  
  const checkAuth = () => {
    setCheckTrigger(true)
    setIsLoading(true)
    setCheckLoad(true)
    try {
      // Make an API call to the backend to check authentication status and retrieve token and user info
       api.get('checkauth').then((response)=>{
        console.log(response.data);
        if (response.status === 200){
            setToken(response.data.user)
            setIsAuth(true)
            const lastPath = window.location.pathname;
            navigate(lastPath);
            // navigate('/admin')

        }
        else{
          setToken('unauthorized')
          setIsAuth(false)
            console.log('lllllo');
        }
    }).catch((error)=>{

      setToken("unauthorized");
    })
    } catch (error) {
      console.error('Failed to check authentication:', error);
      if (error.code === "ERR_NETWORK"){
        navigate('/serverdown')
      }else{
        navigate('/login')
      }
      
    } finally {
      setIsLoading(false)
      setCheckLoad(true);
    }
  };


  useEffect(() => {
    if (!checkTrigger){

      checkAuth();
    }
  }, []);

  if (isLoading){
    return <LoadingIndicator/>
  }

//   if (token === null) {
//     // Render a loading indicator or redirect to a loading page
//     return <LoadingIndicator />;
//   }

  return (
    <AuthContext.Provider value={{ token,setToken,isAuth,checkAuth,checkLoad }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
