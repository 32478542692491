import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Avatar, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { toast } from "react-toastify";
import { CustomToast } from "../customtoast/CustomToast";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// import DataUsageIcon from '@mui/icons-material/DataUsage';
// import BuildIcon  from '@mui/icons-material/Build ';
import SelectableCheckbox from "../AssetPage/CheckBox";
import { AuthContext } from "../../AuthContext";
import api from "../../axios";
import { useState } from "react";
// import { coreBaseUrl } from "../../constants";

const assetType = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <AddCircleOutlineIcon />
      <Typography variant="body2">Datum</Typography>
    </Box>
  );
};

export default function AddCompanyModal({ type,info, updateData }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const { token } = React.useContext(AuthContext);
  const [subContract, setSubContract] = useState('')
  const [contractsData, setContractsData] = useState([]);

  const company_id = token.company_id ? token.company_id : token.id;
  const handleClose = () => {
    setFormData({
      company: company_id,
      typeOfAsset: "Heavy vehicle",
      fuelType: "",
      assetState: "",
      sub_contract:'',
      assetRegistrationNumber: "",
      assetCapacity: "",
      assetName: "",
      assetLocation: "",
      assetPincode: 0,
      staff_incharge: "",
    });
    setSelectedDate(null);
    setOpen(false);
  };

  
  

  const [formData, setFormData] = React.useState({
    company: company_id,
    typeOfAsset: "Heavy vehicle",
    fuelType: "",
    assetState: "",
    assetRegistrationNumber: "",
    assetCapacity: "",
    sub_contract:'',
    assetName: "",
    assetLocation: "",
    assetPincode: 0,
    staff_incharge: "",
  });


  

  const assetIcons = {
    All: require("../../assets/industry.png"),
    Datum: require("../../assets/datum.png"),
    HeavyVehicle: require("../../assets/truck.png"),
    InfraMachine: require("../../assets/citytech-logo.png"),
    DgSet: require("../../assets/generator.png"),
    Industrial: require("../../assets/factory.png"),
    Others: require("../../assets/oil-pump.png"),
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    maxHeight: "80vh",
    bgcolor: colors.background[900],
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
  };

  const [selectedDate, setSelectedDate] = React.useState(null);

  const [userData, setUserData] = React.useState([]);

  const handleChange = (event) => {
    console.log(formData);
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAssetChange = (value) => {
    setFormData((prevData) => ({ ...prevData, ["typeOfAsset"]: value }));
    if (value === "Datum") {
      setFormData((prevData) => ({ ...prevData, ["assetCapacity"]: "2000" }));
    } else {
      setFormData((prevData) => ({ ...prevData, ["assetCapacity"]: "" }));
    }
    console.log("9090909090909090", formData);
  };

  const handleQty = (value) => {
    setFormData((prevData) => ({ ...prevData, ["assetCapacity"]: value }));
    console.log(formData);
  };

  const handleSubmit = () => {
    console.log(token.id);

    api
      .post("assets", formData)
      .then((response) => {
        console.log(response.data);
        updateData();
        handleClose();
      })
      .catch((error) => {
        const errorMessages = error.response.data.message;
        const keys = Object.keys(errorMessages);
        const values = Object.values(errorMessages);

        toast.error(<CustomToast keys={keys} values={values} />);
        if (error.response.status === 403){
          window.location.reload()
        }
      });
  };

  const getStaff = () => {
    const params = {
      staff_only: true,
    };
    api.get(`staff/${company_id}`, { params }).then((response) => {
      setUserData(response.data);
    }).catch((error)=>{
      if (error.response.status === 403){
        window.location.reload()
      }
    });
  };

  const [locations, setLocations] = useState([]);

  const coreBaseUrl = process.env.REACT_APP_ADMIN_BASEURL;
  const getLocations = async () => {
    await axios.get(`${coreBaseUrl}location/${company_id}`).then((response) => {
      setLocations(response.data);
    }).catch((error)=>{
      if (error.response.status === 403){
        window.location.reload()
      }
    });
  };

  const getSubContracts = () => {
    api.get(`sub-contracts/${company_id}`).then((response)=>{
      setContractsData(response.data)
    })
  }

  React.useEffect(() => {
  if (type === 'edit'){
    setFormData({
      company: token.id,
      typeOfAsset: info.assetType,
      fuelType: "",
      assetState: "",
      assetRegistrationNumber: "",
      assetCapacity: "",
      assetName: info.assetName,
      assetLocation: "",
      assetPincode: 0,
      staff_incharge: "",
    });
  }
    getLocations();
    getStaff();
    getSubContracts()
  }, []);

  return (
    <div>
      {type==='add'?
      <Button
      variant="outlined"
        sx={{
          boxShadow:'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
          backgroundColor: colors.back['main'],
          border:'1px solid chocolate',
          color: "chocolate",
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
          borderRadius: 3,
          "&:hover": {
            backgroundColor: '#0b325a',
          },

          "@media (max-width: 600px)": {
            fontSize: "8px",
            padding: "8px 16px",
          },
        }}
        onClick={handleOpen}
      >
        <AddCircleOutlineIcon />
        &nbsp;Add Asset
      </Button>
    :
    <MenuItem onClick={handleOpen}>Edit</MenuItem>
    }
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h3" align="center">
            Add Your Asset Details
          </Typography>
          <Box
            sx={{
              position: "absolute",
              top: 20,
              right: 20,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography
              variant="body1"
              sx={{ marginLeft: 1, cursor: "pointer" }}
              onClick={handleClose}
            >
              Close
            </Typography>
            <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
          </Box>

          <Box>
            <Grid container spacing={2}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <Typography variant="subtitle1" gutterBottom>
                  Asset Type
                </Typography>
                <Box display="flex" flexDirection="row" overflow="auto">
                  <SelectableCheckbox
                    name="Datum"
                    checked={formData.typeOfAsset === "Datum"}
                    value="Datum"
                    onChange={() => handleAssetChange("Datum")}
                    icon={
                      <img
                        src={assetIcons["Datum"]}
                        style={{ filter: colors.pngcol["filter"] }}
                        width="25"
                        height="25"
                      />
                    }
                  />
                  <SelectableCheckbox
                    name="Heavy vehicle"
                    checked={formData.typeOfAsset === "Heavy vehicle"}
                    value="Heavy vehicle"
                    onChange={() => handleAssetChange("Heavy vehicle")}
                    icon={
                      <img
                        src={assetIcons["HeavyVehicle"]}
                        width="30"
                        height="30"
                      />
                    }
                  />
                  <SelectableCheckbox
                    name="DG set"
                    checked={formData.typeOfAsset === "DG set"}
                    value="DG set"
                    onChange={() => handleAssetChange("DG set")}
                    icon={
                      <img src={assetIcons["DgSet"]} width="30" height="30" />
                    }
                  />
                  <SelectableCheckbox
                    name="Industrial"
                    checked={formData.typeOfAsset === "Industrial"}
                    value="Industrial"
                    onChange={() => handleAssetChange("Industrial")}
                    icon={
                      <img
                        src={assetIcons["Industrial"]}
                        width="30"
                        height="30"
                      />
                    }
                  />

                  <SelectableCheckbox
                    name="Infra machine"
                    checked={formData.typeOfAsset === "Infra machine"}
                    value="Infra machine"
                    onChange={() => handleAssetChange("Infra machine")}
                    icon={
                      <img
                        src={assetIcons["InfraMachine"]}
                        width="30"
                        height="30"
                      />
                    }
                  />

                  <SelectableCheckbox
                    name="Others"
                    checked={formData.typeOfAsset === "Others"}
                    value="Others"
                    onChange={() => handleAssetChange("Others")}
                    icon={
                      <img src={assetIcons["Others"]} width="30" height="30" />
                    }
                  />
                </Box>
              </FormControl>
              <Grid item xs={12} md={6}>
              
                {formData.typeOfAsset === "Datum" ||
                formData.typeOfAsset === "DG set" ||
                formData.typeOfAsset === "Infra machine" ||
                formData.typeOfAsset === "Heavy vehicle" ? (
                  ""
                ) : (
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="asset-state-label">Asset State</InputLabel>
                    <Select
                      name="assetState"
                      value={formData.assetState}
                      labelId="asset-state-label"
                      onChange={handleChange}
                      variant="filled"
                    >
                      <MenuItem value="stationary">Stationary</MenuItem>
                      <MenuItem value="movable">Movable</MenuItem>
                    </Select>
                  </FormControl>
                )}

                  

                {formData.typeOfAsset === "Heavy vehicle" ? (
                  <FormControl fullWidth margin="normal">
                    <TextField
                      name="assetRegistrationNumber"
                      value={formData.assetRegistrationNumber}
                      label="Registration Number"
                      variant="filled"
                      onChange={handleChange}
                    />
                  </FormControl>
                ) : (
                  ""
                )}

                <FormControl fullWidth margin="normal">
                  <TextField
                    name="assetName"
                    value={formData.assetName}
                    label="Preferred Name"
                    variant="filled"
                    onChange={handleChange}
                  />
                </FormControl>

                <select
              value={formData.sub_contract}
              name='sub_contract'
              onChange={handleChange}
              className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-indigo-500 focus:shadow-outline"
            >
              <option value="">Select Sub Contract</option>

              {contractsData.map((obj) => (
                <option value={obj.id}>{obj.trade_name}</option>
              ))}
            </select>

                <FormControl fullWidth margin="normal">
                  <InputLabel id="asset-state-label">Location</InputLabel>
                  <Select
                    name="assetLocation"
                    value={formData.assetLocation}
                    onChange={handleChange}
                    variant="filled"
                  >
                    {locations.map((obj) => {
                      return (
                        <MenuItem value={obj.id}>
                          <Box
                            width="100%"
                            display="flex"
                            flexDirection="row"
                            gap="5px"
                            justifyContent="space-between"
                          >
                            <Box display="flex" flexDirection="row" gap="6px">
                              {/* <Avatar /> */}
                              <Box
                                display="flex"
                                flexDirection="column"
                                marginTop="-3px"
                              >
                                <Typography fontWeight="700">
                                  {obj.location}
                                </Typography>

                                {/* <Typography fontSize="13px">
                                  {obj.number}
                                </Typography> */}
                              </Box>
                            </Box>
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                {/* <FormControl fullWidth margin="normal">
                  <TextField
                    name="assetPincode"
                    value={formData.assetPincode}
                    label="Pincode"
                    variant="filled"
                    onChange={handleChange}
                  />
                </FormControl> */}
              </Grid>

              <Grid item xs={12} md={6}>
                {formData.typeOfAsset !== "Datum" ? (
                  <FormControl fullWidth margin="normal">
                    <TextField
                      name="assetCapacity"
                      value={formData.assetCapacity}
                      label="Capacity in Liters"
                      variant="filled"
                      onChange={handleChange}
                    />
                    <div
                      style={{
                        display: "flex",
                        marginTop: "8px",
                        gap: 5,
                        overflow: "auto",
                        paddingTop: "5px",
                        "&::-webkit-scrollbar": {
                          width: "10px",
                        },
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: colors.grey[900],
                          color: colors.pure[900],
                          "&:hover": { bgcolor: colors.grey[800] },
                        }}
                        onClick={() => {
                          handleQty("50");
                        }}
                      >
                        50
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: colors.grey[900],
                          color: colors.pure[900],
                          "&:hover": { bgcolor: colors.grey[800] },
                        }}
                        onClick={() => {
                          handleQty("100");
                        }}
                      >
                        100
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: colors.grey[900],
                          color: colors.pure[900],
                          "&:hover": { bgcolor: colors.grey[800] },
                        }}
                        onClick={() => {
                          handleQty("200");
                        }}
                      >
                        200
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: colors.grey[900],
                          color: colors.pure[900],
                          "&:hover": { bgcolor: colors.grey[800] },
                        }}
                        onClick={() => {
                          handleQty("300");
                        }}
                      >
                        300
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: colors.grey[900],
                          color: colors.pure[900],
                          "&:hover": { bgcolor: colors.grey[800] },
                        }}
                        onClick={() => {
                          handleQty("500");
                        }}
                      >
                        500
                      </Button>

                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: colors.grey[900],
                          color: colors.pure[900],
                          "&:hover": { bgcolor: colors.grey[800] },
                        }}
                        onClick={() => {
                          handleQty("1000");
                        }}
                      >
                        1000
                      </Button>

                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: colors.grey[900],
                          color: colors.pure[900],
                          "&:hover": { bgcolor: colors.grey[800] },
                        }}
                        onClick={() => {
                          handleQty("");
                        }}
                      >
                        Custom
                      </Button>
                    </div>
                  </FormControl>
                ) : (
                  ""
                )}

<FormControl fullWidth margin="normal">
                    <TextField
                      name="Odometer"
                      value={formData.Odometer}
                      label="Current Odometer"
                      variant="filled"
                      onChange={handleChange}
                    />
                  </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="asset-state-label">Staff Incharge</InputLabel>
                  <Select
                    name="staff_incharge"
                    value={formData.staff_incharge}
                    onChange={handleChange}
                    variant="filled"
                  >
                    {userData.map((obj) => {
                      return (
                        <MenuItem value={obj.id}>
                          <Box
                            width="100%"
                            display="flex"
                            flexDirection="row"
                            gap="5px"
                            justifyContent="space-between"
                          >
                            <Box display="flex" flexDirection="row" gap="6px">
                              <Avatar />
                              <Box
                                display="flex"
                                flexDirection="column"
                                marginTop="-3px"
                              >
                                <Typography fontWeight="700">
                                  {obj.username}
                                </Typography>

                                <Typography fontSize="13px">
                                  {obj.number}
                                </Typography>
                              </Box>
                            </Box>

                            {/* <Box marginTop="-3px" width='25%' paddingLeft='5px'>
                          <Typography>
                            {obj.assetRegistrationNumber !== ""
                              ? `Reg No : ${obj.assetRegistrationNumber}`
                              : `Capacity : ${obj.assetCapacity} Ltrs`}
                          </Typography>

                          <Typography variant='p' fontSize="14px" >
                          
                            Location : {obj.assetLocation.length > 15 ? `${obj.assetLocation.slice(0, 15)}...` : obj.assetLocation}
                          </Typography>
                        </Box> */}
                          </Box>
                        </MenuItem>
                      );
                    })}

                    {/* <MenuItem value="movable">Movable</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
              backgroundColor: "#3e4396",
              alignItems: "center",
              width: "30%",
              ml: "35%",
              mt: "20px",
            }}
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
