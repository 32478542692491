import { Box, useTheme } from "@mui/material";
import { tokens } from "../theme";
import Header from "../components/Header";
import { useContext, useEffect, useState } from "react";
import api from "../axios";
import AddAssetModal from "../components/StaffPage/AddStaffModal";
import MainContext from "../contexts/MainContext";
import { useMediaQuery } from '@mui/material'
import { AuthContext } from "../AuthContext";
import StaffBoxNew from "../components/StaffPage/StaffBoxNew";

const AssetPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const {token} = useContext(AuthContext)
  const [rowData, setRowData] = useState([]);
  const { setAssetFilter } = useContext(MainContext);
  const isMobile = useMediaQuery('(max-width : 600px)')

  const getData = () => {
    api.get(`staff/${token.id}`).then((response) => {
      const staffData = response.data.filter((user) => user.role === 'staff');
      const managerData = response.data.filter((user) => user.role === 'manager');
      console.log('====================================');
      console.log(response.data);
      console.log('====================================');
      setStaffData(staffData);
      setManagerData(managerData);
    }).catch((error)=>{
      if (error.response.status === 403){
        window.location.reload()
      }
    });
  }

  const [staffData, setStaffData] = useState([]);
  const [managerData, setManagerData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box sx={{ marginInline: "20px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Your People" subtitle="Manage your staff and managers" />
        <Box display='flex' direction='row' gap='2px'>
          <AddAssetModal type='staff' updateData={getData}></AddAssetModal>
          <AddAssetModal type='manager' updateData={getData}></AddAssetModal>
          {/* <AddManagerModal updateData={getData}></AddManagerModal> */}
        </Box>
      </Box>

      {/* <Box maxWidth="70vw">
        <Carousel />
      </Box> */}

      <Box
        borderRadius='10px'
        height={isMobile ? "600px" :'70vh'}
        // marginTop="20px"
        overflow='auto'
      >
        {/* <StaffBox data={data}/> */}
        <StaffBoxNew updateData={getData} staffData={staffData} managerData={managerData}/>

      </Box>
    </Box>
  );
};

export default AssetPage;
